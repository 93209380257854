.card-collapse{
    .card{
        margin-bottom: 25px;

        .card-header{
            position: relative;
            padding: .75rem 1.25rem;
            padding-left: 0;
            padding-right: 0;

            a[data-toggle="collapse"]{
                display: block;
                color: $light-black;

                i{
                    float: right;
                    position: relative;
                    color: #f96332;
                    top: 1px;
                    @extend .animation-transition-general;
                }
            }

            &:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $light-gray;
            }
        }

        .card-body{
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}
