.img-selected {
	border: 3px solid black;
}
.file-info {
	border-bottom: 3px solid black;
}
.files-list,
.files-upload {
	display: flex;
	flex-wrap: wrap;

	.file {
		width: 25%;
		padding: 0px 10px;
		.file-img {
			max-width: 135px;
			max-height: 135px;
		}
		.file-btn {
			width: 100%;
			height: 30px;
		}
	}
}
.section-modal {
	&:focus {
		outline: 0 !important;
	}
	.modal-dialog {
		max-width: 1000px;
		input {
			width: 100%;
		}
		.modal-body {
			.files-list-modal {
				.files-uploader {
					.file {
						position: relative;
						button {
							width: 30px;
							height: 30px;
							position: absolute;
							bottom: 75%;
							left: 80%;
							border-radius: 50%;
						}
					}
				}
				.el-select {
					color: #c0c4cc !important;
					width: 100%;
					.el-input input.el-input__inner {
						background-color: #ffd141 !important;
						border-radius: 30px !important;
						font-size: 1em;
						width: 100%;
						color: #fff;
						&::placeholder {
							color: #808080 !important;
							opacity: 1 !important;
						}
					}
					.el-input__sufix {
						color: #c0c4cc !important;
					}
				}
			}
		}
		.modal-dialog.modal-sm {
			max-width: 400px;
		}
	}
}
