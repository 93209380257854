<template>
	<component
		class="dropdown"
		:is="tag"
		:class="[
			{ show: isOpen },
			{ dropdown: direction === 'down' },
			{ dropup: direction === 'up' }
		]"
		aria-haspopup="true"
		:aria-expanded="isOpen"
		@click="toggleDropDown"
		v-click-outside="closeDropDown"
	>
		<slot name="title">
			<a
				class="dropdown-toggle nav-link"
				:class="{ 'no-caret': hideArrow }"
				data-toggle="dropdown"
			>
				<i :class="icon"></i>
				<span class="no-icon">{{ title }}</span>
			</a>
		</slot>
		<ul
			class="dropdown-menu"
			:class="[
				{ 'dropdown-menu-right': position === 'right' },
				{ show: isOpen }
			]"
		>
			<slot></slot>
		</ul>
	</component>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DropDown extends Vue {
	@Prop({ type: String, default: "down" }) direction!: string;
	@Prop({ type: String }) title!: string;
	@Prop({ type: String }) icon!: string;
	@Prop({ type: String }) position!: string;
	@Prop({ type: Boolean }) hideArrow!: boolean;
	@Prop({ type: String, default: "li" }) tag!: string;
	private isOpen = false;

	toggleDropDown() {
		this.isOpen = !this.isOpen;
		this.$emit("change", this.isOpen);
	}
	closeDropDown() {
		this.isOpen = false;
		this.$emit("change", this.isOpen);
	}
}
</script>
<style>
.dropdown {
	list-style-type: none;
}

.dropdown .dropdown-toggle {
	cursor: pointer;
}
</style>
