<template>
	<div class="row">
		<div class="col-12">
			<card card-body-classes="table-full-width" no-footer-line>
				<h4 slot="header" class="card-title">{{ title }}</h4>
				<div>
					<div
						class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
					>
						<pk-input>
							<el-input
								type="search"
								class="mb-3"
								clearable
								prefix-icon="el-icon-search"
								style="width: 200px"
								placeholder="Search records"
								v-model="paginationData.searchQuery"
								aria-controls="datatables"
							>
							</el-input>
						</pk-input>
					</div>
					<div class="col-12 d-flex flex-wrap">
						<slot name="remove"></slot>
						<slot name="add"></slot>
					</div>
					<el-table
						stripe
						style="width: 100%;"
						:data="tableData.results"
						@sort-change="sortChange"
						@select="onSelect"
						@select-all="onSelect"
						v-show="tableData.results.length > 0"
					>
						<el-table-column
							v-if="isEditable"
							type="selection"
							width="45"
							:selectable="canSelectRow"
						>
						</el-table-column>
						<el-table-column
							v-for="column in tableColumns.filter(
								(e) => !e.hidden
							)"
							:key="column.label"
							:min-width="column.minWidth"
							:prop="column.prop"
							:label="column.label"
							sortable="custom"
						>
							<template slot-scope="scope">
								<div
									v-if="
										typeof scope.row[column.prop] !=
											'boolean'
									"
								>
									<template
										v-if="
											typeof scope.row[column.prop] ===
												'string' &&
												column.maxLength !== null &&
												scope.row[column.prop].length >
													column.maxLength
										"
									>
										<el-tooltip placement="bottom">
											<span>
												{{
													scope.row[
														column.prop
													].substring(
														0,
														column.maxLength
													) + "..."
												}}
											</span>
											<div slot="content">
												{{ scope.row[column.prop] }}
											</div>
										</el-tooltip>
									</template>
									<el-tooltip
										v-else-if="column.tooltip"
										placement="bottom"
									>
										<div
											slot="content"
											v-html="
												scope.row[
													`${column.prop}Tooltip`
												]
											"
										></div>
										<span>
											{{ scope.row[column.prop] }}
										</span>
									</el-tooltip>
									<p v-else class="mb-0">
										{{ scope.row[column.prop] }}
									</p>
								</div>
								<div v-else>
									<i
										v-if="scope.row[column.prop]"
										class="fa fa-check"
									></i>
									<i v-else class="fa fa-times"></i>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							:min-width="150"
							fixed="right"
							:label="$t('table.Actions')"
							v-if="!disableActionsColumn"
						>
							<div slot-scope="props" class="table-actions">
								<slot v-bind:data="props" name="actions">
								</slot>
							</div>
						</el-table-column>
					</el-table>
					<div
						class="text-center flex-column"
						v-show="tableData.results.length === 0"
					>
						<i
							v-if="isLoading || loading"
							class="now-ui-icons loader_gear spin"
						></i>
						<div>
							{{
								isLoading || loading
									? $t("table.Fetching")
									: $t("table.NoData")
							}}
						</div>
					</div>
				</div>
				<div
					slot="footer"
					class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
				>
					<div class="">
						<p class="card-category">
							{{
								$t("table.TotalEntries")
									.replace("|FROM|", from + 1)
									.replace("|TO|", to)
									.replace("|TOTAL|", tableData.count)
							}}
						</p>
					</div>
					<pagination
						v-if="!loading"
						class="pagination-no-border"
						v-model="paginationData.page"
						:per-page="paginationData.pageSize"
						:total="tableData.count"
					>
					</pagination>
					<div class="">
						<label style="margin-right: 10px;">
							{{ $t("table.RowsPerPage") }}
						</label>
						<select
							v-model="paginationData.pageSize"
							placeholder="Per page"
						>
							<option
								v-for="item in perPageOptions"
								:key="item"
								:label="item"
								:value="item"
							>
							</option>
						</select>
					</div>
				</div>
			</card>
		</div>
	</div>
</template>
<script lang="ts">
import { Table, TableColumn, Select, Option } from "element-ui";
import Pagination from "./Pagination.vue";
import { Column } from "@/types/Table/Column";

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { TableQuery } from "@/api/requests/TableQuery";
import { TableData } from "@/types/Table/TableData";

@Component({
	components: {
		Pagination,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn
	}
})
export default class AppTable extends Vue {
	@Prop({ type: Array, required: true }) tableColumns!: Column[];
	@Prop({ type: Object, required: true }) tableData!: TableData<any>;
	@Prop({ type: Boolean, required: true }) loading!: boolean;
	@Prop({ type: String, required: true }) title!: string;
	@Prop({ type: Boolean, required: false })
	isEditable!: boolean;
	@Prop({ type: Array, required: false, default: () => [10, 25, 50] })
	perPageOptions!: number[];
	@Prop({ type: Boolean, required: false })
	disableActionsColumn!: boolean;
	@Prop({ type: Boolean, required: false }) fetchOnRemove!: boolean;

	private paginationData: TableQuery = {
		page: 1,
		pageSize: 25,
		sortDirection: "descending",
		sortColumn: this.tableColumns[0].prop,
		searchQuery: "",
		shouldSort: true
	};

	private queryTimer = 0;
	private isLoading = false;

	get to() {
		let highBound = this.from + this.paginationData.pageSize;
		if (this.tableData.count < highBound) {
			highBound = this.tableData.count;
		}
		return highBound;
	}
	get from() {
		return this.paginationData.pageSize * (this.paginationData.page - 1);
	}

	get page() {
		return this.paginationData.page;
	}
	get searchQuery() {
		return this.paginationData.searchQuery;
	}

	get pageSize() {
		return this.paginationData.pageSize;
	}

	get sortData() {
		return {
			pageSize: this.paginationData.pageSize,
			sortDirection: this.paginationData.sortDirection,
			sortColumn: this.paginationData.sortColumn
		};
	}
	mounted() {
		this.onSortChanged();
	}

	@Emit("rowSelected")
	onSelect(data: Array<any>) {
		return data;
	}

	sortChange(sortProps: any) {
		this.paginationData.page = 1;
		this.paginationData.sortDirection = sortProps.order;
		this.paginationData.sortColumn = sortProps.prop;
		this.paginationData.shouldSort = sortProps.order !== null;
	}

	@Emit("sortChanged")
	onSortChanged(pageReset = false) {
		if (pageReset) {
			this.paginationData.page = 1;
		}
		return this.paginationData;
	}

	@Watch("page")
	onPageChanged() {
		this.onSortChanged();
	}

	@Watch("pageSize")
	onPageSizeChanged() {
		this.onSortChanged(true);
	}

	@Watch("sortData")
	onSortDataChanged() {
		this.onSortChanged(false);
	}

	@Watch("searchQuery")
	onQueryChanged() {
		this.isLoading = true;
		if (this.queryTimer !== 0) {
			clearTimeout(this.queryTimer);
		}
		this.queryTimer = setTimeout(() => {
			this.isLoading = false;
			this.onSortChanged(true);
		}, 1000);
	}

	canSelectRow(row: any) {
		if (row.isSelectableRow !== undefined) {
			return row.isSelectableRow;
		}
		return true;
	}

	@Watch("fetchOnRemove")
	onPaginationRequest() {
		this.onSortChanged();
	}
}
</script>
<style>
.el-table .cell {
	text-overflow: unset;
}
</style>
