
.editor-title {
	button {
		&.btn {
			margin: 0px !important;
		}
	}
}
.empty-sections-panel {
	background-color: #000000;
	height: 100%;
	color: #ffffff;
}
.form-group input[type="file"] {
	opacity: unset !important;
	position: unset !important;
}
