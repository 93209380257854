.reportsEditor {
	@import "bootstrap";
}
.section-explorer {
	width: 250px !important;

	.list-group {
		border: none;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 60vh;
		.list-group-item {
			border: none;
			font-size: 12px;
		}
	}

	.multiselect {
		font-size: 12px;
		.multiselect__single {
			font-size: 12px;
		}
	}

	.show-choosen {
		.el-checkbox__label {
			font-size: 12px;
		}
	}

	.btn-transparent {
		background-color: transparent !important;
		border: none !important;
		&:hover {
			box-shadow: none !important;
		}
		&:active {
			box-shadow: none !important;
			outline: none !important;
		}
		&.danger {
			color: red;
		}
		&.success {
			color: green;
		}
	}

	.flip-list-move {
		transition: transform 0.5s;
	}
	.no-move {
		transition: transform 0s;
	}

	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}
	small {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
	.handle {
		cursor: pointer;
	}
	input {
		display: inline-block;
		width: 50%;
	}

	.small-font {
		font-size: 12px;
		margin-left: 10px;
	}
}
