.navbar {
	.dropdown.show .dropdown-menu {
		display: block;
	}

	.dropdown .dropdown-menu {
		display: none;
	}

	.dropdown.show .dropdown-menu,
	.dropdown .dropdown-menu {
		background-color: transparent;
		border: 0;
		transition: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: auto;
		margin: 0px 1rem;
		margin-top: 0px;

		&:before {
			display: none;
		}
	}

	.dropdown-menu .dropdown-item {
		color: white !important;
	}

	.dropdown-menu .dropdown-item:focus,
	.dropdown-menu .dropdown-item:hover {
		color: $white-color;
	}

	&.bg-white .dropdown-menu .dropdown-item:focus,
	&.bg-white .dropdown-menu .dropdown-item:hover {
		color: $default-color;
	}

	.navbar-toggler-bar {
		display: block;
		position: relative;
		width: 22px;
		height: 1px;
		border-radius: 1px;
		background: $white-color;

		& + .navbar-toggler-bar {
			margin-top: 7px;
		}

		& + .navbar-toggler-bar.navbar-kebab {
			margin-top: 3px;
		}

		&.bar2 {
			width: 17px;
			transition: width 0.2s linear;
		}
	}

	&.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
		background-color: $default-color;
	}

	& .toggled .navbar-toggler-bar {
		width: 24px;

		& + .navbar-toggler-bar {
			margin-top: 5px;
		}
	}
}

.wrapper {
	@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
}

.nav-open {
	.sidebar {
		@include transform-translate-x(0px);
	}
}

.bar1,
.bar2,
.bar3 {
	outline: 1px solid transparent;
}
.bar1 {
	top: 0px;
	@include bar-animation($topbar-back);
}
.bar2 {
	opacity: 1;
}
.bar3 {
	bottom: 0px;
	@include bar-animation($bottombar-back);
}
.toggled .bar1 {
	top: 6px;
	@include bar-animation($topbar-x);
}
.toggled .bar2 {
	opacity: 0;
}
.toggled .bar3 {
	bottom: 6px;
	@include bar-animation($bottombar-x);
}

@include topbar-x-rotation();
@include topbar-back-rotation();
@include bottombar-x-rotation();
@include bottombar-back-rotation();

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.section-nucleo-icons .icons-container {
	margin-top: 65px;
}

.navbar-nav {
	.nav-link {
		i.fa,
		i.now-ui-icons {
			opacity: 0.5;
		}
	}
}

.sidebar,
.bootstrap-navbar {
	@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

	@include transform-translate-x(-260px);
}

.main-panel {
	width: 100%;
}

.navbar-collapse {
	background: none !important;
}

.navbar-toggler-bar {
	display: block;
	position: relative;
	width: 22px;
	height: 1px;
	border-radius: 1px;
	background: $white-color;

	& + .navbar-toggler-bar {
		margin-top: 7px;
	}

	& + .navbar-toggler-bar.navbar-kebab {
		margin-top: 3px;
	}

	&.bar2 {
		width: 17px;
		transition: width 0.2s linear;
	}
}

.toggled .navbar-toggler-bar {
	width: 24px;

	& + .navbar-toggler-bar {
		margin-top: 5px;
	}
}

.sidebar-toggler {
	height: 60px;
	&:hover {
		background-color: rgba(200, 200, 200, 0.2) !important;
	}
	.navbar-toggler-bar {
		background: $black-color !important;
	}
}
@media screen and (min-width: 992px) {
	.dropdown-menu {
		&.dropdown-menu-right {
			background: $white-color !important;
			.dropdown-item {
				color: $black-color !important;
			}
		}
	}
}
