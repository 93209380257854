.logo-image {
	max-width: 150px !important;
	max-height: 80px !important;
}
input[type="text"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="text"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}
