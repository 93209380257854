
$scaleSize: 0.95;
@keyframes zoomIn95 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
	to {
		opacity: 1;
	}
}
.main-panel .zoomIn {
	animation-name: zoomIn95;
}
@keyframes zoomOut95 {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
}
.main-panel .zoomOut {
	animation-name: zoomOut95;
}
.nav-header {
	color: white;
	font-size: 14px;
}
.nav-divider {
	width: 88%;
	background: white;
}
