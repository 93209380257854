<template>
	<div
		class="modal fade"
		@click.self="closeModalOnOuterClick"
		:class="[
			{ 'show d-block': show },
			{ 'd-none': !show },
			{ 'modal-mini': type === 'mini' }
		]"
		v-show="show"
		tabindex="-1"
		role="dialog"
		:aria-hidden="!show"
	>
		<div
			class="modal-dialog"
			:class="[{ 'modal-notice': type === 'notice' }, modalClasses]"
		>
			<div class="modal-content">
				<div
					class="modal-header"
					:class="headerClasses"
					v-if="showHeader"
				>
					<slot name="close-button">
						<button
							type="button"
							v-if="showClose"
							@click="closeModal"
							class="close"
							data-dismiss="modal"
							:aria-hidden="!show"
						>
							<i class="now-ui-icons ui-1_simple-remove"></i>
						</button>
					</slot>
					<slot name="header"></slot>
				</div>

				<div class="modal-body" :class="bodyClasses">
					<slot></slot>
				</div>

				<div
					class="modal-footer"
					:class="footerClasses"
					v-if="showFooter && $slots.footer"
				>
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
	@Prop({ type: Boolean }) show!: boolean;
	@Prop({ type: Boolean, default: true }) showHeader!: boolean;
	@Prop({ type: Boolean, default: true }) showFooter!: boolean;
	@Prop({ type: Boolean, default: true }) showClose!: boolean;
	@Prop({
		type: String,
		default: "",
		validator(value: string) {
			const acceptedValues = ["", "notice", "mini"];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	type!: string;

	@Prop({ type: [Object, String] }) modalClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) headerClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) bodyClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: [Object, String] }) footerClasses!: [
		Record<string, any>,
		string
	];
	@Prop({ type: Number, default: 500 }) animationDuration!: number;

	@Prop({ type: Boolean, default: false }) closeOnOuterClick!: boolean;

	closeModal() {
		this.update();
		this.close();
	}

	closeModalOnOuterClick() {
		if (this.closeOnOuterClick) {
			this.closeModal();
		}
	}

	@Emit("close")
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	close() {}
	@Emit("update:show")
	update() {
		return false;
	}
	@Watch("show")
	onShowChanged(val: boolean) {
		const documentClasses = document.body.classList;
		if (val) {
			documentClasses.add("modal-open");
		} else {
			documentClasses.remove("modal-open");
		}
	}
}
</script>
<style>
.modal.show {
	background-color: rgba(0, 0, 0, 0.3);
}
</style>
