<template>
	<component
		:is="baseComponent"
		:to="link.path ? link.path : '/'"
		:class="{ active: isActive }"
		tag="li"
	>
		<a
			v-if="isMenu"
			href="#"
			class="nav-link sidebar-menu-item"
			:aria-expanded="!collapsed"
			data-toggle="collapse"
			@click.prevent="collapseMenu"
		>
			<i :class="link.icon"></i>
			<p>
				{{ link.name }}
				<b class="caret"></b>
			</p>
		</a>

		<div v-if="$slots.default || this.isMenu" v-show="!collapsed">
			<ul class="nav links__nav">
				<slot></slot>
			</ul>
		</div>

		<slot
			name="title"
			v-if="children.length === 0 && !$slots.default && link.path"
		>
			<component
				:to="`/${link.path}`"
				@click.native="linkClick"
				:is="elementType(link, false)"
				:class="{ active: link.active }"
				class="nav-link"
				:target="link.target"
				:href="`/${link.path}`"
			>
				<template>
					<i
						v-if="link.child === true"
						class="now-ui-icons arrows-1_minimal-right"
						style="margin-left: 1.5rem;"
					></i>
					<i :class="link.icon"></i>
					<p>{{ link.name }}</p>
				</template>
			</component>
		</slot>
	</component>
</template>
<script lang="ts">
import { Component, Inject, Prop, Provide, Vue } from "vue-property-decorator";

@Component
export default class SideBarItem extends Vue {
	@Prop({ type: Boolean, default: false }) menu!: boolean;
	@Prop({
		type: Object,
		default: () => {
			return {
				name: "",
				path: "",
				children: []
			};
		}
	})
	link!: any;

	@Provide("addLink") addLink2 = this.addChild;
	@Provide("removeLink") removeLink2 = this.removeChild;

	@Inject({ default: null })
	readonly addLink!: (item: any) => void;
	@Inject({ default: null })
	readonly removeLink!: (item: any) => void;
	@Inject({ default: true })
	readonly autoClose!: any;

	private children: any[] = [];
	private collapsed = true;

	get baseComponent() {
		return this.isMenu || this.link.isRoute ? "li" : "router-link";
	}
	get linkPrefix() {
		if (this.link.name) {
			const words = this.link.name.split(" ");
			return words.map((word: string) => word.substring(0, 1)).join("");
		}
		return ``;
	}
	get isMenu() {
		return this.children.length > 0 || this.menu === true;
	}
	get isActive() {
		if (this.$route && this.$route.path) {
			const matchingRoute = this.children.find((c: any) =>
				this.$route.path.startsWith(c.link.path)
			);
			if (matchingRoute !== undefined) {
				return true;
			}
		}
		return false;
	}

	addChild(item: any) {
		const index = (this.$slots.default as any).indexOf(item.$vnode);
		this.children.splice(index, 0, item);
	}
	removeChild(item: any) {
		const tabs = this.children;
		const index = tabs.indexOf(item);
		tabs.splice(index, 1);
	}
	elementType(link: any, isParent = true) {
		if (link.isRoute === false) {
			return isParent ? "li" : "a";
		} else {
			return "router-link";
		}
	}
	linkAbbreviation(name: string) {
		const matches = name.match(/\b(\w)/g);
		if (matches) return matches.join("");
	}
	linkClick() {
		if (
			this.autoClose &&
			this.$sidebar &&
			this.$sidebar.showSidebar === true
		) {
			this.$sidebar.displaySidebar(false);
		}
	}
	collapseMenu() {
		this.collapsed = !this.collapsed;
	}
	collapseSubMenu(link: any) {
		link.collapsed = !link.collapsed;
	}
	mounted() {
		if (this.addLink) {
			this.addLink(this);
		}
		if (this.link.collapsed !== undefined) {
			this.collapsed = this.link.collapsed;
		}
		if (this.isActive && this.isMenu) {
			this.collapsed = false;
		}
	}
	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
		if (this.removeLink) {
			this.removeLink(this);
		}
	}
}
</script>
<style>
.sidebar-menu-item {
	cursor: pointer;
}
.sidebar ul.links__nav {
	margin-top: 0;
	padding-top: 10px;
}
</style>
