.card-lock{
    .card-header{
        img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-top: -60px;
            box-shadow: $box-shadow-raised;
        }
    }

}
