@media screen and (max-width: 991px) {
	.profile-photo .profile-photo-small {
		margin-left: -2px;
	}

	.button-dropdown {
		display: none;
	}

	#minimizeSidebar {
		display: none;
	}

	.wrapper {
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
	}

	.nav-open {
		.sidebar {
			@include transform-translate-x(0px);
		}

		body {
			position: relative;
			overflow-x: hidden;
		}

		.menu-on-right {
			.main-panel {
				@include transform-translate-x(-260px);
			}

			.navbar-collapse,
			.sidebar {
				@include transform-translate-x(0px);
			}

			.navbar-translate {
				@include transform-translate-x(-300px);
			}

			#bodyClick {
				right: 260px;
				left: auto;
			}
		}
	}

	.menu-on-right {
		.sidebar {
			left: auto;
			right: 0;
			@include transform-translate-x(260px);
		}
	}

	.bar1,
	.bar2,
	.bar3 {
		outline: 1px solid transparent;
	}
	.bar1 {
		top: 0px;
		@include bar-animation($topbar-back);
	}
	.bar2 {
		opacity: 1;
	}
	.bar3 {
		bottom: 0px;
		@include bar-animation($bottombar-back);
	}
	.toggled .bar1 {
		top: 6px;
		@include bar-animation($topbar-x);
	}
	.toggled .bar2 {
		opacity: 0;
	}
	.toggled .bar3 {
		bottom: 6px;
		@include bar-animation($bottombar-x);
	}

	@include topbar-x-rotation();
	@include topbar-back-rotation();
	@include bottombar-x-rotation();
	@include bottombar-back-rotation();

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@-moz-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	#bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 1;
		top: 0;
		right: 0;
		left: 260px;
		content: "";
		z-index: 9999;
		overflow-x: hidden;
		background-color: transparent;
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
	}

	.footer {
		.copyright {
			text-align: right;
		}
	}

	.section-nucleo-icons .icons-container {
		margin-top: 65px;
	}

	.navbar-nav {
		.nav-link {
			i.fa,
			i.now-ui-icons {
				opacity: 0.5;
			}
		}
	}

	.sidebar,
	.bootstrap-navbar {
		position: fixed;
		display: block;
		top: 0;
		height: 100%;
		width: 260px;
		right: auto;
		left: 0;
		z-index: 1032;
		visibility: visible;
		overflow-y: visible;
		padding: 0;
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

		@include transform-translate-x(-260px);
	}

	.main-panel {
		width: 100%;
	}
}

@media screen and (min-width: 992px) {
	.navbar-nav {
		.nav-link {
			&.profile-photo {
				padding: 0;
				margin: 7px $padding-base-horizontal;
			}
		}
	}

	.section-nucleo-icons .icons-container {
		margin: 0 0 0 auto;
	}

	.dropdown-menu .dropdown-item {
		color: inherit;
	}

	.footer {
		.copyright {
			float: right;
			padding-right: 15px;
		}
	}
}

@media screen and (max-width: 768px) {
	.nav-tabs {
		display: inline-block;
		width: 100%;
		padding-left: 100px;
		padding-right: 100px;
		text-align: center;

		.nav-item > .nav-link {
			margin-bottom: 5px;
		}
	}

	.card-stats [class*="col-"] .statistics::after {
		display: none;
	}

	.main-panel .content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.footer {
		nav {
			display: block;
			margin-bottom: 5px;
			float: none;
		}
	}

	.landing-page .section-story-overview .image-container:nth-child(2) {
		margin-left: 0;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 576px) {
	.navbar[class*="navbar-toggleable-"] .container {
		margin-left: 0;
		margin-right: 0;
	}

	.card-contributions .card-stats {
		flex-direction: column;

		.bootstrap-switch {
			margin-bottom: 15px;
		}
	}

	.footer {
		.copyright {
			text-align: center;
		}
	}

	.section-nucleo-icons {
		.icons-container {
			i {
				font-size: 30px;

				&:nth-child(6) {
					font-size: 48px;
				}
			}
		}
	}

	.page-header {
		.container h6.category-absolute {
			width: 90%;
		}
	}
}
